import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import Table from "../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  deleteProduct,
  fetchProducts,
  editStatus,
} from "../redux/slices/productsSlice";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader/loader";
import Check from "../components/Check";

const Products = () => {
  const navigate = useNavigate(); // Get the history object for navigation
  const handleEnableEditing = (productId) => {
    // Redirect to the "/edit/:id" route with the appropriate productId
    navigate(`/products/edit/${productId}`);
  };
  const dispatch = useDispatch();
  let products = useSelector((state) => state.products.data);
  const loading = useSelector((state) => state.products.isLoading);
  const { state } = useLocation();

  useEffect(() => {
    if (localStorage.getItem("force") == "ok") {
      localStorage.removeItem("force");
      window.location.reload(false);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("force_edit_prod") == "ok") {
      localStorage.removeItem("force_edit_prod");
      window.location.reload();
    }
  }, []);
  useEffect(() => {
    dispatch(fetchProducts());
    // window.location.reload();
  }, [dispatch]);

  const productsColumns = [
    {
      header: "English Name",
      accessorKey: "name",
    },
    {
      header: "اسم المنتج",
      accessorKey: "name_trans",
    },
    {
      header: "Price",
      accessorKey: "price",
    },
    // {
    //   header: "taste",
    //   accessorKey: "taste",
    // },
    // {
    //   header: "Category",
    //   accessorKey: "Category",
    // },
    // {
    //   header: "calories",
    //   accessorKey: "calories",
    // },
    {
      header: "Ingredients",
      accessorKey: "ingredients",
    },
    {
      header: "المكونات",
      accessorKey: "ingredients_trans",
    },
    {
      header: "Image",
      accessorKey: "image",
      Cell: function (params) {
        return <LazyLoadImage src={params.row.original.image} />;
      },
    },

    {
      header: "ACTIONS",
      accessorKey: "actions",
      Cell: function (params) {
        return (
          <div className="flex justify-between">
            <Button
              sx={{ marginRight: "0.5rem w-4" }}
              variant="outlined"
              color="primary"
              onClick={() => handleEnableEditing(params.row.original.id)}
            >
              Edit
            </Button>
            <Button
              color="error"
              variant="outlined"
              className="text-red-700 bg-red-600 mr-3 w-3"
              onClick={() => {
                handleDeleteProduct(params.row.original.id);
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
    {
      header: "Status",
      accessorKey: "status",
      Cell: function (params) {
        //console.log(params.row.original.status)
        // Pass the productId to the handleToggleStatus function

        let Checked = false;
        if (params.row.original.status == "1") {
          Checked = true;
        } else {
          Checked = false;
        }

        return (
          <Switch
            onClick={() => {
              handleToggleStatus(params.row.original.id);
            }}
            checked={Checked}
          />
        );
      },
    },
  ];

  const handleDeleteProduct = (productId) => {
    dispatch(deleteProduct(productId))
      .then(() => {
        // Handle successful deletion if needed
        console.log("Product deleted successfully!");
        window.location.reload(false);
      })
      .catch((error) => {
        // Handle errors if needed
        console.error("Error deleting product:", error);
      });
  };

  const handleToggleStatus = (productId) => {
    dispatch(editStatus(productId))
      .then(() => {
        console.log("Status changed successfully!");
        window.location.reload();
      })
      .catch((error) => {
        // Handle errors if needed
        console.error("Error changing status:", error);
      });
  };

  const [confirm, setConfirm] = useState({ status: false, id: "" });

  return (
    <Box
      sx={{
        pt: "80px",
        pb: "20px",
        overflow: "auto",
        position: "relative",
        minheight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Products</Typography>
        <Link to="/products/add" style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FiPlus />}
            sx={{ borderRadius: "20px" }}
          >
            Add Product
          </Button>
        </Link>
      </Box>
      <Box>
        {products.length >= 1 ? (
          <Table
            data={products}
            fields={productsColumns}
            numberOfRows={products.length}
            enableTopToolBar={true}
            enableBottomToolBar={true}
            enablePagination={true}
            enableRowSelection={true}
            enableColumnFilters={true}
            enableColumnDragging={true}
          />
        ) : loading ? (
          <Loader />
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export default Products;
