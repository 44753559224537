import React from "react";
import { Box, Button, Typography } from "@mui/material";
import "./loader.css";

const Loader = () => {
  return (
    <Box sx={{ margin: "8rem auto" }}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Box>
  );
};

export default Loader;
