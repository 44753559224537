/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { Button, IconButton, Tooltip } from "@mui/material";
import { FiEdit, FiEye, FiTrash } from "react-icons/fi";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

export const Table = ({
  data,
  fields,
  numberOfRows,
  enableTopToolBar,
  enableBottomToolBar,
  enablePagination,
  enableColumnFilters,
  showPreview,
  routeLink,
  onDelete,
}) => {
  const columns = fields.map((field) => ({
    ...field,
    // Adjust the width for each column as needed
    width: '50px', // or use minWidth/maxWidth as needed
  }));
    const [status, setStatus] = useState({});
  const handleStatusChange = useCallback(
    (row) => {
      const newStatus = !status[row.id];
      setStatus((prevStatus) => ({ ...prevStatus, [row.id]: newStatus }));
      // Call the API here to update the status with `row.id` and `newStatus`
      // Example: `axios.put(`edit_status/${row.id}`, { status: newStatus })`
    },
    [status]
  );
  const [tableData, setTableData] = useState(() => data);

  const handleDeleteRow = useCallback(
    (row) => {
      if (!confirm("Are you sure you want to delete")) {
        return;
      }
      onDelete(row.id);

      setTableData((prevData) => {
        const newData = [...prevData];
        newData.splice(row.index, 1);
        return newData;
      });
    },
    [onDelete]
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={tableData.slice(0, numberOfRows)}
      getRowId={(row) => row.id}
      enableColumnFilters={enableColumnFilters}
      enablePagination={enablePagination}
      enableBottomToolbar={enableBottomToolBar}
      enableTopToolbar={enableTopToolBar}
      renderRowActions={({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Tooltip arrow placement="right" title="Delete">
            <IconButton
              sx={{ color: "#602723" }}
              onClick={() => handleDeleteRow(row)}
            >
              <FiTrash />
            </IconButton>
          </Tooltip>
          {showPreview && routeLink && (
            <Tooltip arrow placement="right" title="View">
              <Link to={`/${routeLink}/${row.id}`}>
                <IconButton>
                  <FiEye />
                </IconButton>
              </Link>
            </Tooltip>
          )}

          <Tooltip arrow placement="right" title="Edit">
            <Link to={`/${routeLink}/edit/${row.id}`}>
              {" "}
              {/* Modified link */}
              <IconButton>
                <FiEdit />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
      )}
      muiTableBodyRowProps={{ hover: false }}
      muiTablePaperProps={{
        sx: {
          padding: "3px",
          borderRadius: "15px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          width: "80vw",
        },
      }}
      muiTableContainerProps={{
        sx: { 
          maxWidth: '80vw', // Controls the maximum width of the table container
        },
      }}
      muiTableProps={{
        sx: {
          tableLayout: 'fixed',
          width: {
            xs: 'auto', // On extra-small and small screens, use auto width
            sm: 'auto', // Adjust if you want a different behavior on small screens
            lg: '80vw', // From medium screens upwards, use 80vw
            // You can continue with lg or xl if you need further customization
          },
        },
      }}
      
      muiTableHeadCellProps={{
        sx: {
          fontSize: "0.875rem", // Adjust font size as needed
          padding: "6px 8px", // Reduce padding as needed
          fontWeight: "bold",
          width: "20px",
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          fontSize: "0.875rem",
          padding: "6px 8px",
          width: "20px",
        },
      }}
      muiTableHeadProps={{
        sx: {
          "& tr th": {
            borderWidth: "1px",
            borderColor: "divider",
            borderStyle: "solid",
            width: "20px",
          },
        },
      }}
      muiTableBodyProps={{
        sx: {
          "& tr td": {
            borderWidth: "1px",
            borderColor: "divider",
            borderStyle: "solid",
            width: "20px",
          },
        },
      }}
    />
  );
};

export default Table;
