import styled from "@emotion/styled";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiImageAdd } from "react-icons/bi";
import { fetchCategories } from "../redux/slices/categoriesSlice";

import { fetchProducts, storeProduct } from "../redux/slices/productsSlice";
import Loader from "../components/loader/loader";
import { Link, Navigate, useNavigate } from "react-router-dom";

const AddProduct = () => {
  const imageInput = useRef(null);
  const navigate = useNavigate();

  const [productData, setProductData] = useState({
    name: "",
    ingredients: "",
    category_id: "",
    rate: "",
    price: "",
    calories: "1",
    position: null,
    description: "",
    name_trans: "",
    ingredients_trans: "",
    taste: "",
    taste_trans: "",
    image: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const storeProductError = useSelector((state) => state.products.error);
  const loading = useSelector((state) => state.products.isLoading);
  const data = useSelector((state) => state.products.success);

  const categories = useSelector((state) => state.categories.data);

  const handleChange = (event) => {

    setProductData({
      ...productData,
      [event.target.name]: event.target.value,
    });
    console.log(productData)
  };

  const handleImageChange = (e) => {
    setProductData({
      ...productData,
      image: e.target.files[0],
    });
  };

  const [error, setError] = useState(false);
  const [dataMessage, setDataMessage] = useState(false);
  useEffect(() => {
    if (storeProductError) {
      setError(true);
    } else {
      setError(false);
    }
  }, [storeProductError]);

  useEffect(() => {
    if (data === true) {
      setDataMessage(true);
    }
  }, [data]);
  useEffect(() => {
    setDataMessage(false);
  }, []);

  const [errorField, setErrorField] = useState(false);
  const [helperText, setHelperText] = useState("");

  const handleSubmit = () => {
    let productData2 = productData;
    console.log(productData2)

    if (
      productData.taste?.trim().length === 0 ||
      productData.taste_trans.trim().length === 0
    ) {
      if (
        productData.ingredients.trim().length === 0 ||
        productData.ingredients_trans.trim().length === 0
      ) {
        productData2 = {
          ...productData,
          ingredients: "No ingredients",
          ingredients_trans: "لا يوجد",
          taste: "No taste",
          taste_trans: "لا يوجد",
        };
      } else {
        productData2 = {
          ...productData,
          taste: "No taste",
          taste_trans: "لا يوجد",
        };
      }
    }

    if (
      productData.calories?.trim().length === 0 ||
      productData.name.trim().length === 0 ||
      productData.price.trim().length === 0 ||
      productData.name_trans.toString().trim().length === 0
    ) {
      setErrorField(true);
      setHelperText("Required");
    } else {
      setErrorField(false);
      setHelperText("");
   
      dispatch(storeProduct(productData2)).then(()=>{
        localStorage.setItem("force", "ok")
        navigate('/products')
      })
      
      // navigate("/products", { state: { refresh: true } });
    }

    // Navigate to '/products'
  };

  const UploadBox = styled(Box)({
    marginTop: 30,
    height: 200,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderStyle: "dashed",
    borderWidth: "2px",
    borderColor: "divider",
  });

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "14px" }}>
        Add Product
        {error && (
          <p style={{ color: "red" }}>Something went wront , Try agin</p>
        )}
        {dataMessage && <p style={{ color: "green" }}>Stored Successfully</p>}
      </Typography>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "800px",
          margin: "0 auto",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
              <TextField
                error={errorField}
                helperText={helperText}
                required
                name="name"
                label="Product English Name"
                variant="outlined"
                size="small"
                fullWidth
                value={productData.name}
                onChange={handleChange}
              />

              <TextField
                required
                error={errorField}
                helperText={helperText}
                name="name_trans"
                label="Product Arabic Name"
                variant="outlined"
                size="small"
                fullWidth
                value={productData.name_trans}
                onChange={handleChange}
              />
            </Box>
            <Box sx={{ mt: 4 }}>
              <TextField
                required
                helperText={helperText}
                name="ingredients"
                label="Ingredients In English"
                variant="outlined"
                rows={4}
                fullWidth
                multiline
                value={productData.ingredients}
                onChange={handleChange}
              />
            </Box>
            <Box sx={{ mt: 4 }}>
              <TextField
                required
                name="ingredients_trans"
                label="Ingredients In Arabic"
                variant="outlined"
                rows={4}
                fullWidth
                multiline
                value={productData.ingredients_trans}
                onChange={handleChange}
              />
            </Box>
            <Box sx={{ mt: 4 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  required
                  helperText={helperText}
                  error={errorField}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Category"
                  name="category_id"
                  value={productData.category_id}
                  onChange={handleChange}
                >
                  {categories?.map(({ id, name }) => (
                    <MenuItem value={id} key={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
              <TextField
                required
                helperText={helperText}
                error={errorField}
                name="price"
                type="number"
                label="Price"
                variant="outlined"
                rows={4}
                fullWidth
                size="small"
                defaultValue={"$234.24"}
                value={productData.price}
                onChange={handleChange}
              />
              <TextField
                required
                name="calories"
                helperText={helperText}
                error={errorField}
                label="Calories"
                variant="outlined"
                rows={4}
                type="number"
                fullWidth
                size="small"
                defaultValue={"20%"}
                value={productData.calories}
                onChange={handleChange}
              />
            </Box>

            <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
              <TextField
                required
                name="position"
                label="position"
                variant="outlined"
                size="small"
                fullWidth
                value={productData.position}
                onChange={handleChange}
              />
              </Box>
                     {/**   <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
              <TextField
                required
                name="taste"
                label="taste"
                variant="outlined"
                size="small"
                fullWidth
                value={productData.taste}
                onChange={handleChange}
              />

              <TextField
                required
                name="taste_trans"
                label="حساسية الطعم"
                variant="outlined"
                size="small"
                fullWidth
                value={productData.taste_trans}
                onChange={handleChange}
              />
            </Box>
          */} 
            <input
              type="file"
              hidden
              ref={imageInput}
              onChange={handleImageChange}
            />
            <UploadBox onClick={() => imageInput.current.click()}>
              {productData.image ? (
                <img
                  src={URL.createObjectURL(productData.image)}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <Box sx={{ textAlign: "center" }}>
                  <BiImageAdd style={{ fontSize: "50px", color: "#027edd" }} />
                  <Typography>
                    Drop your image here or{" "}
                    <span style={{ color: "#027edd", cursor: "pointer" }}>
                      browse
                    </span>
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    JPG, PNG and GIF images are allowed
                  </Typography>
                </Box>
              )}
            </UploadBox>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: "30px",
              }}
            >
              <Button
               
                onClick={handleSubmit}
                variant="contained"
                sx={{ borderRadius: "20px" }}
              >
                Submit
              </Button>
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default AddProduct;
