import { FiLayers, FiPauseCircle, FiPlus, FiShoppingBag } from "react-icons/fi";

export const links = [
  {
    name: "Products",
    icon: <FiShoppingBag />,
    url: "/products",
  },
  {
    name: "Add Product",
    icon: <FiPlus />,
    url: "/products/add",
  },
  {
    name: "Product Category",
    icon: <FiLayers />,
    url: "/products/categories",
  },
  {
    name: "Offers",
    icon: <FiPauseCircle />,
    url: "/Offers",
  },
  {
    name: "Add Offer",
    icon: <FiPlus />,
    url: "/offer/add",
  },
];
