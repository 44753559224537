import { lazy, Suspense, useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Sidebar from "./components/common/Sidebar";
import Navbar from "./components/common/Navbar";
import { useNavigate } from "react-router-dom";

import { Products, EditProduct, EditCategory, Login } from "./pages";
import Loader from "./components/loader/loader";
import Check from "./components/Check";

const sideBarWidth = 250;

const AddProduct = lazy(() => import("../src/pages/AddProduct.jsx"));
const ProductCategories = lazy(() => import("../src/pages/ProductCategories"));
const Offer = lazy(() => import("../src/pages/Offer/Index"));
const OfferAdd = lazy(() => import("../src/pages/Offer/Add"));

function App() {
  const navigate = useNavigate(); // Get the history object for navigation

  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const onLoginPage = location.pathname === "/";
  useEffect(() => {
    if (
      localStorage.getItem("token") == undefined ||
      localStorage.getItem("token") == null ||
      localStorage.getItem("token") == ""
    ) {
      navigate("/");
    } else {
      console.log("you may enter");
      console.log("token " + localStorage.getItem("token"));
    }
    //  console.log(token ? "true" : "false");
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      {onLoginPage ? null : (
        <>
          <Navbar
            sideBarWidth={sideBarWidth}
            handleDrawerToggle={handleDrawerToggle}
          />
          <Sidebar
            sideBarWidth={sideBarWidth}
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
        </>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: { xs: 1, md: 2 },
          width: { xs: "100%", md: `calc(100% - ${sideBarWidth}px)` },
        }}
      >
        {/* Routes */}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/products" element={<Products />} />

          <Route
            path="/products/add"
            element={
              <Suspense fallback={<Loader></Loader>}>
                <AddProduct />
              </Suspense>
            }
          />
          <Route
            path="/products/categories"
            element={
              <Suspense fallback={<Loader></Loader>}>
                <ProductCategories />
              </Suspense>
            }
          />
          <Route path="/products/edit/:id" element={<EditProduct />} />
          <Route path="/categories/edit/:id" element={<EditCategory />} />
          <Route
            path="/offers"
            element={
              <Suspense fallback={<Loader></Loader>}>
                <Offer />{" "}
              </Suspense>
            }
          />
          <Route
            path="/offer/add"
            element={
              <Suspense fallback={<Loader></Loader>}>
                <OfferAdd />
              </Suspense>
            }
          />
        </Routes>
      </Box>
    </Box>
  );
}

export default App;
